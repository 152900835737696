import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';


export default function Wuziqi() {
  const canvasRef = useRef(null);
  const soundEffectRef=useRef(null)
  const [board, setBoard] = useState(Array(15).fill(0).map(() => Array(15).fill(0)));
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [hoverPos, setHoverPos] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [socket, setSocket] = useState(null);
  const [gameStatus, setGameStatus] = useState(false);
  const [myplayerid, setMyPlayerId] = useState(null);
  const [undotimes, setUndoTimes] = useState(3);
  const roomidref = useRef(null);
  const [undosuccess,setundosuccess]=useState(false)
  const [istwoready,setistwoready]=useState(false)

  function resetGameStatus() {
    setGameStatus(false);
  }

  function againgame() {
    socket.send(JSON.stringify({ type: 'againgame' }));
  }

  function joinCreateRoom(roomid) {
    if(gameId){
      window.alert("当前房间："+gameId+"，如需重新进入房间，请刷新！")
      roomidref.current.value=''
      return
    }
    setGameId(roomid);
    socket.send(JSON.stringify({
      type: 'join',
      gameId: roomid
    }));
    window.alert("操作成功！");
    roomidref.current.value=''
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      joinCreateRoom(roomidref.current.value);
    }
  }

  useEffect(() => {
    if (gameStatus) {
      if (currentPlayer === myplayerid) {
        console.log("myplayerid", myplayerid);
        window.alert("你输了！");
        resetGameStatus(false)
      } else {
        console.log("myplayerid:", myplayerid);
        window.alert("你赢了！");
        resetGameStatus(false)
      }
    }
  }, [gameStatus]);



  useEffect(() => {
    const ws = new WebSocket('wss://music.coderyy.site:5500');
    setSocket(ws);
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case 'gameStart':
          setMyPlayerId(data.player);
          setCurrentPlayer(1);
          break;
        case 'updateBoard':
          setBoard(data.board);
          setCurrentPlayer(data.currentPlayer);
          break;
        case 'win':
          setGameStatus(true);
          break;
        case 'undoRequest':
          const confirmUndo = window.confirm('对方请求悔棋，你同意吗？');
          if (confirmUndo) {
            ws.send(JSON.stringify({ type: 'acceptUndo'}));
          }
          break;
        case 'undoAccepted':
          setBoard(data.board);
          setundosuccess(true)
          break;
        case 'againgame':
          resetGameStatus();
          break;
        case 'readygame':
          setistwoready(true)
          window.alert("游戏开始")
          break
        case 'roomfull':
          window.alert("当前房间已满！")
          setGameId(null)
          break;
        default:
          break;
      }
    };

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  useEffect(()=>{
    if(undosuccess===true){
      if(currentPlayer===myplayerid){
        window.alert("悔棋成功！")
        setUndoTimes(undotimes-1)
      }
      setundosuccess(false)
    }
  },[undosuccess])

  const drawChessboard = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const cellSize = 40;
    const boardSize = 600;
    ctx.clearRect(0, 0, boardSize, boardSize);

    ctx.strokeStyle = '#aaa';
    for (let i = 0; i < 15; i++) {
      ctx.beginPath();
      ctx.moveTo(20, 20 + i * cellSize);
      ctx.lineTo(20 + (14 * cellSize), 20 + i * cellSize);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(20 + i * cellSize, 20);
      ctx.lineTo(20 + i * cellSize, 20 + (14 * cellSize));
      ctx.stroke();
    }

    const points = [
      [3, 3], [3, 11], [11, 3], [11, 11], [7, 7],
    ];
    ctx.fillStyle = '#000';
    points.forEach(([x, y]) => {
      ctx.beginPath();
      ctx.arc(20 + x * cellSize, 20 + y * cellSize, 5, 0, 2 * Math.PI);
      ctx.fill();
    });

    board.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (cell !== 0) {
          drawPiece(x, y, cell, ctx);
        }
      });
    });

    if (hoverPos && board[hoverPos.y][hoverPos.x] === 0) {
      ctx.beginPath();
      ctx.arc(
        20 + hoverPos.x * cellSize,
        20 + hoverPos.y * cellSize,
        15,
        0,
        2 * Math.PI
      );
      ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
      ctx.fill();
    }
  };

  const drawPiece = (x, y, player, ctx) => {
    const cellSize = 40;
    ctx.beginPath();
    ctx.arc(20 + x * cellSize, 20 + y * cellSize, 15, 0, 2 * Math.PI);
    ctx.fillStyle = player === 1 ? '#000' : '#fff';
    ctx.fill();
    ctx.strokeStyle = '#000';
    ctx.stroke();
  };

  const handleCanvasClick = (e) => {
    if (!socket || gameId === null || gameStatus || myplayerid !== currentPlayer) return;
    if(!istwoready){
      if(myplayerid===1){
        window.alert("对方还没有进入房间！")
      }
      return;
    }

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const cellSize = 40;
    const x = Math.floor((e.clientX - rect.left) / cellSize);
    const y = Math.floor((e.clientY - rect.top) / cellSize);

    if (x >= 0 && x < 15 && y >= 0 && y < 15 && board[y][x] === 0) {
      const position = [x, y];
      // 发送移动数据
      socket.send(
        JSON.stringify({
          type: 'move',
          gameId,
          player: myplayerid,
          position,
        })
      );
      soundEffectRef.current.play()
    }
  };

  const handleMouseMove = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const cellSize = 40;
    const x = Math.floor((e.clientX - rect.left) / cellSize);
    const y = Math.floor((e.clientY - rect.top) / cellSize);
    if (x >= 0 && x < 15 && y >= 0 && y < 15) {
      setHoverPos({ x, y });
    } else {
      setHoverPos(null);
    }
  };

  const handleMouseLeave = () => {
    setHoverPos(null);
  };

  const handleUndo = () => {
    if (gameStatus) return;

    // Send undo request to the server
    socket.send(JSON.stringify({ type: 'undo', gameId, player: myplayerid }));
  };

  useEffect(() => {
    drawChessboard();
  }, [hoverPos, board]);


  return (
    <>
      <audio ref={soundEffectRef} src="https://api.music.coderyy.site/soundEffect/wuziqiluozi.mp3"></audio>
      <aside>
        <Link to='/recommend' style={{ position: 'fixed', left: '10%', top:'5%',color: 'lightblue' }}>
          返回
        </Link>
        <button onClick={againgame} style={{position:'absolute',left:'12%',top:'15%'}}>再来一局</button>
      </aside>
      <main style={{ display:'flex',justifyContent:'center'}}>
          <div>
            <h2 style={{textAlign:'center',marginBottom:'2px'}}>五子棋</h2>
            <div style={{position:'absolute',top:'12%',right:'5%'}}>
              <p style={{textAlign:'center',marginBottom:'22px'}}>当前房间id：{gameId}</p>
              <input
                ref={roomidref}
                onKeyDown={handleKeyPress}
                placeholder='输入房间号'
              />
              <button onClick={() => joinCreateRoom(roomidref.current.value)}>
                加入或创建
              </button>
            </div>
            <canvas
              ref={canvasRef}
              width={600}
              height={600}
              style={{ border: '1px solid black' }}
              onClick={handleCanvasClick}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            ></canvas>
            <div style={{position:'absolute',top:'33%',right:'10.45%',display:'flex',flexDirection:'column',gap:'19px'}}>
              {
                istwoready?
                <p style={{position:"fixed",left:'9%'}}>你是：<span style={{color:'blue'}}>{myplayerid===1? '黑棋' : '白棋'}</span></p>
                :
                ''
              }
              {
                istwoready?
                <p style={{marginLeft:'2px'}}>当前玩家: <span style={{color:'red'}}>{currentPlayer === 1 ? '黑棋' : '白棋'}</span></p>
                :
                <p style={{marginRight:'22px'}}>比赛未开始</p>
              }
              {
                istwoready?
                <>
                  <p>剩余悔棋次数: {undotimes}</p>
                  <button onClick={handleUndo} disabled={currentPlayer!==myplayerid||undotimes<=0}>请求悔棋</button>
                </>
                :
                ''
              }
            </div>
          </div>
      </main>
    </>
  );
}
